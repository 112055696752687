import { availableLanguages } from "./IntlWrapper/IntlWrapper";
import de from "./localizations/de.json";
import en from "./localizations/en.json";

// these terms need to be defined in the translation files
// as route.term
export const allRoutes = [
  "actors",
  "programme",
  "house",
  "service",
  "shop",
  "contact",
  "press",
  "repertoire",
  "premieres",
  "series",
  "events",
  "grosses-haus",
  "archive",
  "crew",
  "ensemble",
  "team",
  "terms-conditions",
  "imprint",
  "news",
  "page-1",
  "privacy-policy",
  "newsletter",
  "ukraine",
  "prater-studios",
  "p14",
  "gruener-salon",
  "sternschuppen",
  "kino-truck",
  "tickets",
  "cinema",
  "concerts",
  "music",
  "festiwalla",
  "audiodescription",
  "accessibility",
  "accessibility-declaration",
  "jobs",
];

// these terms need to be defined in the translation files
// as menu.term
export const menuItems = {
  play: [
    "calendar",
    "premieres",
    "repertoire",
    // "prater-studios",
    "series",
    "music",
    "p14",
    // "cinema",
    "gruener-salon",
    "sternschuppen",
  ],
  service: [
    "page-1",
    "tickets",
    "archive",
    "crew",
    "contact",
    "press",
    // "audiodescription",
    "accessibility",
  ],
};

export const specialProductionRoutes = ["p14"];

export const pageTabs = {
  repertoire: ["repertoire", "series"],
  crew: ["ensemble", "team"],
};

export const venues = {
  117: "grosses-haus",
  146: "roter-salon",
  147: "gruener-salon",
  148: "dritter-stock",
  149: "probebuehne",
  184: "rosa-luxemburg-platz",
  190: "zirkuszelt",
  168: "sternschuppen",
  199: "pavillon",
  191: "kino-truck",
  153: "parkettcafe",
  150: "sternfoyer",
  163: "on-tour", // on tour
  181: "alle-foyers",
  154: "rechtes-seitenfoyer",
  186: "kassenhalle",
  167: "grosses-haus", // actually Hinterbühne
  170: "grosses-haus", // actually Vorbühne
  156: "prater",
  155: "kantine",
  192: "romski-truck",
  195: "prater-studios-hinterbuehne",
  196: "prater-studios-vorbuehne",
  200: "videothek",
  203: "cabuwazi",
  210: "mecklenburgisches-staatstheater",
  212: "theater-x",
  213: "kiezfuehrung",
  // manually defined venues
  // 900: "babylon",
};

export const PraterStudioVenues = [195, 196];
export const isGrossesHaus = [117, 167, 170];

export const combineVenues = [
  {
    id: 117,
    venues: [
      163,
      191,
      149,
      148,
      184,
      195,
      196,
      200,
      203,
      210,
      212,
      213,
      ...isGrossesHaus,
    ],
  },
  {
    id: 146,
    venues: [146],
  },
  {
    id: 147,
    venues: [147, 190, 168, 153, 150, 155, 181, 186, 154, 156, 192, 199],
  },
];

export const combineVenuesMobile = [
  { id: 117, venues: Object.keys(venues).map((key) => parseInt(key)) },
];

export const venueStructure = [[117], [146], [147]];
export const venueStructureMobile = [[117, 146, 147]];

export const getVenueClass = (venueId) => {
  if (venueId === "festival") return "festival";

  for (let vGroup of combineVenues) {
    if (vGroup.venues.includes(Number(venueId))) {
      return venues[vGroup.id];
    }
  }
};

export const hideTimeForVenues = ["163", "210"];

export const footerItems = [
  "terms-conditions",
  "imprint",
  "privacy-policy",
  "accessibility-declaration",
  "jobs",
  "newsletter",
  "news",
];

export const ignoreSpecial = [
  "Vorstellung",
  "sonst. Veranst.",
  "Gastspiel",
  "Wiederaufnahme",
];

export const displayIgnoredSpecial = ["Gastspiel"];

const translations = {
  de: de,
  en: en,
};

// TODO: memoize?
const getLocalizedRouteNames = () => {
  const localizedRoutes = [];

  for (let route of allRoutes) {
    const routeNames = {};

    for (let lang of availableLanguages) {
      routeNames[lang] = translations[lang][`route.${route}`];
    }

    localizedRoutes.push(routeNames);
  }

  return localizedRoutes;
};

export const getRouteKeys = (lang, key) => {
  const routeNames = getLocalizedRouteNames();
  const partKey = routeNames.find((x) => x[lang] === key);
  return partKey;
};
